import React, { Component } from 'react';

import LayoutLPTwo from '../components/layoutLPTwo';
import SEO from "../components/seo";
import FormField from '../components/utilities/FormField';

import './subscribe.scss';

class OptInForm extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            contactEmail: '',
            contactFirstName: '',
            contactLastName: '',            
            contactPhoneNumber: '',
            contactLinkedInHandle: '',
            contactJobTitle: '',
            contactAddress: '',
            contactAddressLineTwo: '',
            contactCity: '',
            contactZip: '',
            contactConsent: '',
            checked: false
        };

        this.emailChange = this.emailChange.bind(this);
        this.firstNameChange = this.firstNameChange.bind(this);
        this.lastNameChange = this.lastNameChange.bind(this);
        this.phoneNumberChange = this.phoneNumberChange.bind(this);
        this.linkedInHandleChange = this.linkedInHandleChange.bind(this);
        this.jobTitleChange = this.jobTitleChange.bind(this);
        this.streetAddressChange = this.streetAddressChange.bind(this);
        this.addressLineTwoChange = this.addressLineTwoChange.bind(this);
        this.cityChange = this.cityChange.bind(this);
        this.zipChange = this.zipChange.bind(this);
        this.cosentChange = this.consentChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    firstNameChange(e){
        this.setState({contactFirstName: e.target.value})
    }

    lastNameChange(e){
        this.setState({contactLastName: e.target.value})
    }    

    phoneNumberChange(e){
        this.setState({contactPhoneNumber: e.target.value})
    }

    linkedInHandleChange(e){
        this.setState({contactLinkedInHandle: e.target.value})
    }

    jobTitleChange(e){
        this.setState({contactJobTitle: e.target.value})
    }

    streetAddressChange(e){
        this.setState({contactAddress: e.target.value})
    }

    addressLineTwoChange(e){
        this.setState({contactAddressLineTwo: e.target.value})
    }

    cityChange(e){
        this.setState({contactCity: e.target.value})
    }

    zipChange(e){
        this.setState({contactZip: e.target.value})
    }

    consentChange(e){
        // this.setState({contactConsent: e.target.value})
        this.setState({checked: !this.state.checked});
    }

    handleCheck(e) {
        this.setState({checked: !this.state.checked});
    }

    render() {
        return (
            <form id="subForm" name="metrixform" method="POST" action="https://www.createsend.com/t/subscribeerror?description=" data-id="5B5E7037DA78A748374AD499497E309E0964EB16042683CC584201832775DAC99A59DDA8D5BE7E3FEDF7006C52A2C00585003959629D8F22363BBE53646AD78B" className="js-cm-for">
                <FormField fieldID={"fieldEmail"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"cm-ohhojj-ohhojj"} label={"Email"} required={true}/>
                <FormField fieldID={"fielddtjiirth"} valChange={this.firstNameChange} val={this.state.contactFirstName} type={"textnr"}  name={"cm-f-dtjiirth"} label={"First Name"}  required={true}/>
                <FormField fieldID={"fielddtjiirtk"} valChange={this.lastNameChange} val={this.state.contactLastName} type={"textnr"}  name={"cm-f-dtjiirtk"} label={"Last Name"} required={true}/>
                <FormField fieldID={"fielddtjiirtu"}  valChange={this.phoneNumberChange} val={this.state.contactPhoneNumber} type={"tel"} name={"cm-f-dtjiirtu"} label={"Phone Number"}/>
                <FormField fieldID={"fielddtjiiril"}  valChange={this.linkedInHandleChange} val={this.state.contactLinkedInHandle} type={"textnr"}  name={"cm-f-dtjiiril"} label={"LinkedIn Handle"}/>
                <FormField fieldID={"fielddtjiirir"}  valChange={this.jobTitleChange} val={this.state.contactJobTitle} type={"textnr"} name={"cm-f-dtjiirir"} label={"Job Title"}/>
                <FormField fieldID={"fielddtjiirdh"}  valChange={this.streetAddressChange} val={this.state.contactAddress} type={"textnr"} name={"cm-f-dtjiirdh"} label={"Street Address"}/>
                <FormField fieldID={"fielddtjiirdk"}  valChange={this.addressLineTwoChange} val={this.state.contactAddressLineTwo} type={"textnr"} name={"cm-f-dtjiirdk"} label={"Address Line 2"}/>
                <FormField fieldID={"fielddtjiiriy"}  valChange={this.cityChange} val={this.state.contactCity} type={"textnr"} name={"cm-f-dtjiiriy"} label={"City"}/>
                <FormField fieldID={"fielddtjiirid"}  valChange={this.stateChange} val={this.state.contactState} type={"selectstate"} name={"cm-fo-dtjiirid"} label={"State/Province"}/>
                <FormField fieldID={"fielddtjiirur"}  valChange={this.zipChange} val={this.state.contactZip} type={"textnr"} name={"cm-f-dtjiiru"} label={"Zip"}/>
                <FormField fieldID={"fielddtjiirih"}  valChange={this.countryChange} val={this.state.contactCountry} type={"selectcountry"} name={"cm-fo-dtjiirih"} label={"Country"}/>
                
                <div className="form-group">
                    <h2>Marketing Permissions</h2>
                    <p>Merit Mile, LLC ("Merit Mile") will use the information you provide on this form to be in touch with you and to provide updates and marketing communications. By checking the box below, you agree to receive Emails, Direct Mail, and Customized Online Advertising from Merit Mile.</p>
                </div>
                <input type="checkbox" onChange={this.handleCheck} defaultChecked={this.state.checked} name="cm-privacy-email" id="cm-privacy-email" className="largerCheckbox" /> <label>I Consent</label>
                <div className="form-group">
                    <p className="small">You can change your mind at any time by clicking the unsubscribe link in the footer of any email you receive from us. We will treat your information with respect. For more information about our privacy practices please visit our website <a href="https://www.meritmile.com/privacy-policy/" target="_blank" rel="noopener noreferrer">www.meritmile.com/privacy-policy/</a>. By clicking the "I Consent" button and updating your profile, you agree that we may process your information in accordance with these terms.</p>
                </div>
                <button type="submit" className="btn btn-block btn-black">SUBSCRIBE</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://www.meritmile.com/subscribe/" />
                    <input type="hidden" name="auth" value="e7bedb0a-1150-4593-bd82-2274234e1f64" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}

export default ({pageContext}) => (
    <LayoutLPTwo>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className={`${pageContext.slug} subscribe-wrapper`}>
            <div className="container optin-form-wrapper">
                <div className="row">
                    <div className="col-12">
                        <h1>Don't Miss Out On Useful<br /> Marketing Communications</h1>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-6 form-wrapper">
                        <OptInForm />
                        <div className="thankyou">
                            <h4>Thank You</h4>
                            <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutLPTwo>
)